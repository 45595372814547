import React from "react";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination 
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: 12,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
}));

const StyledTableContainer = styled(TableContainer)({
  maxHeight: 440,
});

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '0.875rem',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledTableCell = styled(TableCell)({
  padding: '12px 16px',
});

const FarmerTable = ({
  farmers,
  totalCount,
  currentPage,
  perPage,
  onPageChange,
  onPerPageChange,
}) => {
  return (
    <StyledPaper>
      <StyledTableContainer>
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>
              <StyledTableHeadCell>Name</StyledTableHeadCell>
              <StyledTableHeadCell>Phone</StyledTableHeadCell>
              <StyledTableHeadCell>Age</StyledTableHeadCell>
              <StyledTableHeadCell>Gender</StyledTableHeadCell>
              <StyledTableHeadCell>Created By</StyledTableHeadCell>
              <StyledTableHeadCell>Location</StyledTableHeadCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {farmers.map((item) => (
              <StyledTableRow key={item.farmer.id}>
                <StyledTableCell>{item.farmer.fullname}</StyledTableCell>
                <StyledTableCell>{item.farmer.phone}</StyledTableCell>
                <StyledTableCell>{item.farmer.age}</StyledTableCell>
                <StyledTableCell>{item.farmer.gender}</StyledTableCell>
                <StyledTableCell>{item.farmer.created_by_name}</StyledTableCell>
                <StyledTableCell>
                  {item.location?.city}, {item.location?.state}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={currentPage - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onPerPageChange}
      />
    </StyledPaper>
  );
};

export default FarmerTable;
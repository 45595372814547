import React, { useEffect, useState } from "react";
import { Typography, Container, Box, Alert } from "@mui/material";
import Layout from "./Layout";
import FarmerSearch from "../components/FarmerSearch";
import FarmerTable from "../components/FarmerTable";
import CreatedByFilter from "../components/CreatedByFilter";
import OrderByFilter from "../components/OrderByFilter"; // Import the OrderByFilter component
import { useFarmerList } from "../hooks/useFarmer";

const Farmer = () => {
  const {
    farmers,
    loading,
    totalCount,
    currentPage,
    perPage,
    fetchFarmers,
    handlePageChange,
    handlePerPageChange,
    handleCreatedByFilter,
    handleOrderByChange,
    selectedCreatedBy,
    orderBy,
  } = useFarmerList();

  const [searchFilters, setSearchFilters] = useState({});
  const [searchError, setSearchError] = useState(null);

  useEffect(() => {
    const loadFarmers = async () => {
      try {
        setSearchError(null);

        const result = await fetchFarmers(
          searchFilters,
          currentPage,
          perPage
        );

        if (!result || result.length === 0) {
          setSearchError("No farmers found");
        }
      } catch (err) {
        setSearchError(
          err.message || "An error occurred while searching for farmers."
        );
      }
    };

    loadFarmers();
  }, [
    searchFilters,
    currentPage,
    perPage,
    fetchFarmers,
    selectedCreatedBy,
    orderBy, // Trigger re-fetch when order changes
  ]);

  const handleSearch = (filters) => {
    setSearchFilters((prevFilters) => {
      const filtersChanged =
        JSON.stringify(prevFilters) !== JSON.stringify(filters);
      return filtersChanged ? filters : prevFilters;
    });
  };

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: "80px" }}>
        <Box textAlign="center" mb={3}>
          <Typography variant="h4" fontWeight="bold">
            List of Farmers
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Search using phone or name of farmers
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <FarmerSearch onSearch={handleSearch} />
          <CreatedByFilter
            onSelectUser={handleCreatedByFilter}
            selectedUser={selectedCreatedBy}
          />
          <OrderByFilter
            orderBy={orderBy}
            onChange={handleOrderByChange} // Connect OrderByFilter
          />
        </Box>

        {loading && (
          <Typography>Loading...</Typography>
        )}

        {!loading && searchError && (
          <Box mb={3}>
            <Alert severity="info">{searchError}</Alert>
          </Box>
        )}

        {!loading && !searchError && farmers.length > 0 && (
          <FarmerTable
            farmers={farmers}
            totalCount={totalCount}
            currentPage={currentPage}
            perPage={perPage}
            onPageChange={handlePageChange}
            onPerPageChange={handlePerPageChange}
          />
        )}

        {!loading && !searchError && farmers.length === 0 && (
          <Box textAlign="center" mt={3}>
            <Typography>No farmers found.</Typography>
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default Farmer;
import { useState, useCallback } from "react";
import { fetchFarmers } from "../apis/farmer/farmerApi";

export const useFarmerList = (orgId) => {
  const [farmers, setFarmers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState("ASC");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const org_id = userData.org.id;

  orgId=org_id

  const fetchFarmersList = useCallback(
    async (filters = {}, page = currentPage, itemsPerPage = perPage) => {
      setLoading(true);
      setError(null);
      const userData = JSON.parse(localStorage.getItem("userData"));
      const org_id = userData.org.id;

      try {
        const payload = {
          org_id,
          page: page,
          per_page: itemsPerPage,
          filters: {
            org_id: orgId,
            created_by: selectedCreatedBy,
            ...filters,
          },
          order_by: orderBy,
        };

        const {
          farmers,
          totalCount,
          page: returnedPage,
          per_page,
        } = await fetchFarmers(payload);

        setFarmers(farmers || []);
        setTotalCount(totalCount || 0);
        setCurrentPage(returnedPage);
        setPerPage(per_page);
        setLoading(false);

        return farmers || [];
      } catch (err) {
        setError(err.message);
        setLoading(false);
        return [];
      }
    },
    [orgId, selectedCreatedBy, orderBy, currentPage, perPage] // Removed fetchFarmers from dependencies
  );

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handlePerPageChange = (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const handleCreatedByFilter = (userId) => {
    setSelectedCreatedBy(userId);
    setCurrentPage(1);
  };

  const handleOrderByChange = (order) => {
    setOrderBy(order);
    setCurrentPage(1);
  };

  return {
    farmers,
    loading,
    error,
    totalCount,
    currentPage,
    perPage,
    fetchFarmers: fetchFarmersList,
    handlePageChange,
    handlePerPageChange,
    handleCreatedByFilter,
    handleOrderByChange,
    selectedCreatedBy,
    orderBy,
  };
};
import React, { useCallback, useEffect, useState } from "react";
import Layout from "./Layout";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { baseURL } from "../config";
import { createProgram, listActivities } from "../apis/programs/api";
import CreateActivityModal from "../components/CreateAcitvityModal";
import { useDispatch } from "react-redux";
import {
  createProgramFailure,
  createProgramSuccess,
} from "../reducers/programs";
import { useToast } from "../utils/helpers";

function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

const AddProgram = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgId = userData.org.id;
  const [formData, setFormData] = useState({
    org_id: orgId,
    name: "",
    description: "",
    type: "",
    location: "",
    file: null,
  });

  const [{ open, message, type }, showSuccessToast, showValidationErrorToast] = useToast(
    false,
    'Program created successfully!',
    {
      name: 'Name is required',
      description: 'Description is required',
      type: 'Type is required',
      location: 'Location is required',
      file: 'File is required',
    }
  );
  const [openToastProgram, setOpenToastProgram] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [programId, setProgramId] = useState(null);
  const [activities, setActivities] = useState([]);
  const dispatch = useDispatch();

  const handleChangeProgram = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChangeProgram = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  const handleSubmitProgram = async (e) => {
    e.preventDefault();

    const errors = {
      name: !formData.name ? 'Name is required' : '',
      description: !formData.description ? 'Description is required' : '',
      type: !formData.type ? 'Type is required' : '',
      location: !formData.location ? 'Location is required' : '',
      file: !formData.file ? 'File is required' : '',
    };

    if (Object.values(errors).some(Boolean)) {
      showValidationErrorToast(errors);
      return;
    }

    try {
      const result = await createProgram(baseURL, formData);
      if (result && result.data.program_id) {
        setProgramId(result.data.program_id);
        dispatch(createProgramSuccess(result.data));
        showSuccessToast();
      } else {
        throw new Error("Program ID not found in the response");
      }
    } catch (error) {
      console.error("Failed to create program:", error);
      dispatch(createProgramFailure({ error: error.message }));
    }
  };


  const fetchActivities = useCallback(async () => {
    try {
      // setLoading(true);
      const data = await listActivities(baseURL, orgId, programId);
      setActivities(data.data.activities);
    } catch (err) {
      // setError(err.message);
      console.log(err.message);
    } finally {
      // setLoading(false);
    }
  }, [orgId, programId]);
  useEffect(() => {
    if (orgId && programId) {
      fetchActivities();
    }
  }, [orgId, programId, fetchActivities]);

  useEffect(() => {
    if (openToastProgram) {
      const timer = setTimeout(() => {
        setOpenToastProgram(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [openToastProgram]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#028476",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#028476",
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#028476",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#028476",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: "#028476",
            },
          },
        },
      },
    },
  });

  return (
    <Layout>
      <div className="w-full flex flex-col pl-[75px] pr-[10px] h-[100dvh] gap-5 pb-[10px] pt-[75px] ">
        <h1
          style={{ textAlign: "center" }}
          className="mx-auto font-bold text-[25px]"
        >
          Listing Programme
        </h1>
        <div className="w-full h-full flex flex-row gap-5">
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: 4,
                  width: "100%",
                  border: "1px dashed #028476",
                  boxShadow:
                    "0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)",
                  transition: "box-shadow 0.3s ease-in-out",
                  "&:hover": {
                    boxShadow:
                      "0 0 15px rgba(2, 132, 118, 0.3), 0 0 30px rgba(2, 132, 118, 0.2)",
                  },
                  borderRadius: "10px",
                }}
              >
                <Typography variant="h5" component="h2" gutterBottom>
                  Create Program
                </Typography>
                <form onSubmit={handleSubmitProgram}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Program Name *"
                    name="name"
                    value={formData.name}
                    onChange={handleChangeProgram}
                    autoComplete="off"
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Program Description *"
                    name="description"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={handleChangeProgram}
                    autoComplete="off"
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Type *</InputLabel>
                    <Select
                      name="type"
                      value={formData.type}
                      onChange={handleChangeProgram}
                      label="Type *"
                    >
                      <MenuItem value="soil">Soil</MenuItem>
                      <MenuItem value="water">Water</MenuItem>
                      <MenuItem value="air">Air</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Program Location *"
                    name="location"
                    value={formData.location}
                    onChange={handleChangeProgram}
                    autoComplete="off"
                  />
            <div className="w-full flex flex-row items-center gap-10 pt-4">
  <div className="w-full flex flex-col items-center">
    <Box sx={{ mt: 2, width: "100%", my: "auto" }}>
      <input
        accept="application/pdf"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChangeProgram}
      />
      <label htmlFor="raised-button-file">
        <Button variant="outlined" component="span" fullWidth>
          Upload File *
        </Button>
      </label>
    </Box>
    {formData.file && (
      <Typography variant="body2" sx={{ mt: 1, color: "#028476" }}>
        File selected: {formData.file.name}
      </Typography>
    )}
  </div>
  <Button
    type="submit"
    variant="contained"
    color="primary"
    fullWidth
    sx={{ mt: 3, my: "auto" }}
  >
    Submit
  </Button>
</div>
                </form>
              </Paper>
              {/* <Snackbar
                open={openToastProgram}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert severity="success" sx={{ width: "100%" }}>
                  Program created successfully!
                </Alert>
              </Snackbar> */}
               <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
            </Box>
          </ThemeProvider>
          <div className="w-full overflow-hidden">
            <Box
              className="w-full col-span-2 px-2  rounded-xl h-[80dvh] py-[10px] overflow-hidden"
              sx={{
                border: "1px dashed #028476",
                boxShadow:
                  "0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)",
                transition: "box-shadow 0.3s ease-in-out",
                "&:hover": {
                  boxShadow:
                    "0 0 15px rgba(2, 132, 118, 0.3), 0 0 30px rgba(2, 132, 118, 0.2)",
                },
              }}
            >
              <div className="w-full flex flex-row justify-between items-center h-[50px]">
                <h1 className="font-semibold">Program Activity</h1>
                {programId ? (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "50%",
                      minWidth: 40,
                      minHeight: 40,
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#028476",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#1f5e25",
                      },
                    }}
                    onClick={() => setOpenActivityModal(true)}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <p
                    className={`text-[12px] font-medium ${
                      !programId
                        ? "text-[white] bg-[#028476] p-2 rounded-xl"
                        : ""
                    }`}
                  >
                    {programId ? "" : "First Create Program to Add Activity"}
                  </p>
                )}
                <CreateActivityModal
                  open={openActivityModal}
                  onClose={() => setOpenActivityModal(false)}
                  programId={programId}
                  orgId={orgId}
                  fetchActivities={fetchActivities}
                />
              </div>
              <div className="h-full overflow-x-hidden overflow-hidden  flex flex-col">
                <div className="w-full flex flex-row flex-around items-center pt-2 font-semibold h-[60px] ">
                  <h2 className="w-full text-center">Title</h2>
                  <h2 className="w-full text-center">Type</h2>
                  <h2 className="w-full text-center">Created At</h2>
                </div>
                <div
                  className="h-full overflow-y-auto gap-1 flex flex-col"
                  style={{ height: "calc(80dvh - 130px)" }}
                >
                  {Array.isArray(activities) &&
                    activities.map((activity) => (
                      <div
                        className="w-full flex flex-row flex-around items-center p-2 rounded-lg transition duration-300 hover:bg-[#F2F5F4] text-[14px]"
                        key={activity.id}
                      >
                        <div className="w-full text-center">
                          {activity.title}
                        </div>
                        <div className="w-full text-center">
                          {activity.type}
                        </div>
                        <div className="w-full text-center">
                          {convertTimestamp(activity.created_at)}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddProgram;

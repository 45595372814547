import React, { useState, useEffect } from "react";
import { MenuItem, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { fetchUsers } from "../apis/farmer/farmerApi";

const StyledSelect = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputLabel-root": {
    color: "#008476",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#008476",
  },
});

const CreatedByFilter = ({ onSelectUser, selectedUser }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        console.error("Error loading users:", error);
      }
    };

    loadUsers();
  }, []);

  const handleChange = (event) => {
    const selectedUserId = event.target.value;
    onSelectUser(selectedUserId === "" || selectedUserId === "All" ? undefined : selectedUserId);
  };

  return (
    <StyledSelect
      select
      variant="outlined"
      value={selectedUser === undefined ? "All" : selectedUser}
      onChange={handleChange}
      sx={{
        width: 200,
        "& .MuiSelect-select": {
          color: "black",
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      label="Created By"
    >
      <MenuItem value="All">All</MenuItem>
      {users.map((user) => (
    <MenuItem key={user.id} value={user.id}>
      {user.full_name}
    </MenuItem>
  ))}
    </StyledSelect>
  );
};

export default CreatedByFilter;
import React, { useCallback, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IoChatbubbleSharp } from "react-icons/io5";
import LogoImg from '../images/phyfarmlogo-medium-english 1.png';
import { LuCopy } from "react-icons/lu";
import { Button, ButtonBase } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import {baseURL}from '../config';
import { useNavigate } from 'react-router-dom';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { BsQrCode } from "react-icons/bs";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { IoCloseOutline } from "react-icons/io5";
import { GiFarmTractor } from "react-icons/gi";
import { MdOutlinePayment } from "react-icons/md";
import { VscServerEnvironment } from "react-icons/vsc";
import { MdSupervisedUserCircle } from "react-icons/md";
import { FaUsers } from 'react-icons/fa';
import { Badge } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { FaRegUserCircle } from 'react-icons/fa';
import { FaUserPen } from "react-icons/fa6";
import { BsFillHouseGearFill } from "react-icons/bs";
import { checkAuth, logout } from '../apis/auth/api';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DescriptionIcon from '@mui/icons-material/Description';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { TiTags } from "react-icons/ti";
import { GiFarmer } from "react-icons/gi";



const drawerWidth = 260;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        // backgroundColor:'#E3DDDD',

        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);



const NavigationBar = ({ unreadMSG }) => {

    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [userData, setUserData] = useState({});
    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const [agroDataLocalStorage, setAgroDataLocalStorage] = useState({});
    const [permissions, setPermissions] = useState([]);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const getUserDataFromLocalStorage = useCallback(() => {
        const userDataFromLocalStorage = localStorage.getItem('userData');
        if (userDataFromLocalStorage) {
            const parsedUserData = JSON.parse(userDataFromLocalStorage);
            setUserData(parsedUserData);
            return parsedUserData;
        } else {
            navigate('/', { replace: true });
        }
    }, [navigate]);

    const checkAuthApi = useCallback(async (token) => {
      
        const response = await checkAuth(baseURL, token);
        const data = await response.json();
       
        if(data?.data?.user_permissions?.user_permissions?.permissions != null){
        setPermissions(data?.data?.user_permissions);
        // console.log(data?.data?.user_permissions);
        localStorage.setItem('permissions', JSON.stringify(data?.data?.user_permissions));
        }else{
            setPermissions([]);
            localStorage.removeItem('userData');
            localStorage.removeItem('agroUser');
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('permissions');
            setUserData({});
            navigate('/', { replace: true });
        }
       
    }, [navigate]);

    useEffect(() => {
        const userData = getUserDataFromLocalStorage();
        if (userData) {
            checkAuthApi(userData?.access_token);
        }
    }, [checkAuthApi, navigate, getUserDataFromLocalStorage]);




    const handleLogout = async () => {
        try {

            const logoutResponse = await logout(baseURL, userData?.id, userData?.email, userData?.access_token);

            if (logoutResponse.ok) {

                localStorage.removeItem('userData');
                localStorage.removeItem('agroUser');
                localStorage.removeItem('rememberMe');
                localStorage.removeItem('rememberedEmail');
                localStorage.removeItem('permissions');

                setUserData({});
                navigate('/', { replace: true });
            } else {

                console.error('Logout failed:', logoutResponse.statusText);
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    }

    const agronomistIdMap = {
        'agro_invite_qr_phyfarm': '65c0d2a9ac84ae512f3c916a',
        'agro_invite_qr_vikas_chauhan': '65dd7d820e400ca5404f014a',
        'agro_invite_qr_kaushik': '65dd7df10e400ca5404f014b',
        'agro_invite_qr_tarun_bhardwaj': '65dd7e900e400ca5404f014c',
        'agro_invite_qr_sushant_h_surve': '65dd7edd0e400ca5404f014d',
        'agro_invite_qr_dankan': '65dd7f1b0e400ca5404f014e',
        'agro_invite_qr_prem_more': '65dd7f450e400ca5404f014f',
        'agro_invite_qr_kirubhakaran_rajendran': '65dd7f7e0e400ca5404f0150',
        'agro_invite_qr_vijay_m': '65dd7fef0e400ca5404f0151',
        'agro_invite_qr_akash_wadkar': '65dd801d0e400ca5404f0152',
        'agro_invite_qr_hussain': '65dd803d0e400ca5404f0153',
        'agro_invite_qr_onelabs': '662f3e64e8e87191dad6db83'
    };

    const agronomistLinkMap = {
        '65c0d2a9ac84ae512f3c916a': 'https://manage.phyfarm.com/phyfarm/register-user',
        '65dd7d820e400ca5404f014a': 'https://manage.phyfarm.com/vikas-chauhan/register-user',
        '65dd7df10e400ca5404f014b': 'https://manage.phyfarm.com/kaushik/register-user',
        '65dd7e900e400ca5404f014c': 'https://manage.phyfarm.com/tarun-bhardwaj/register-user',
        '65dd7edd0e400ca5404f014d': 'https://manage.phyfarm.com/sushant-h-surve/register-user',
        '65dd7f1b0e400ca5404f014e': 'https://manage.phyfarm.com/dankan/register-user',
        '65dd7f450e400ca5404f014f': 'https://manage.phyfarm.com/prem-more/register-user',
        '65dd7f7e0e400ca5404f0150': 'https://manage.phyfarm.com/kirubhakaran-rajendran/register-user',
        '65dd7fef0e400ca5404f0151': 'https://manage.phyfarm.com/vijay-m/register-user',
        '65dd801d0e400ca5404f0152': 'https://manage.phyfarm.com/akash-wadkar/register-user',
        '65dd803d0e400ca5404f0153': 'https://manage.phyfarm.com/hussain/register-user',
        '662f3e64e8e87191dad6db83': 'https://manage.phyfarm.com/onelabs/register-user'
    };

    const getAgroData = useCallback(() => {
        const agroData = localStorage.getItem('userData');
        if (agroData) {
            setAgroDataLocalStorage(JSON.parse(agroData));
        }
    }, [])


    useEffect(() => {
        getAgroData();
    }, [getAgroData]);

    const getInviteLink = () => {
        const agroID = agroDataLocalStorage?.id;
        if (agroID && agronomistLinkMap[agroID]) {
            return agronomistLinkMap[agroID];
        } else {
            return null;
        }
    }

    const downloadImage = async () => {
        const agroID = agroDataLocalStorage?.id;
        if (agroID) {
            const userName = Object.keys(agronomistIdMap).find(key => agronomistIdMap[key] === agroID);
            if (userName) {
                
                const imageName = userName.toLowerCase().replace(/\s+/g, '-');
                const imageSrc = `/assets/qrs/${imageName}.png`;

                try {
                    const image = await fetch(imageSrc);
                    if (image.ok) {
                        const imageBlob = await image.blob();
                        const imageURL = URL.createObjectURL(imageBlob);

                        const link = document.createElement('a');
                        link.href = imageURL;
                        link.download = `${imageName}.png`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        console.error('Failed to fetch image:', image.status, image.statusText);
                    }
                } catch (error) {
                    console.error('Error fetching image:', error);
                }
            } else {
                console.error('User not found in agronomistIdMap:', agroID);
            }
        } else {
            console.error('Agronomist ID not found in localStorage');
        }
    }

    const openDialog = () => {
        setOpenCopyDialog(true);
    }

    const featureToSidebarItems = {
        platformUser_mgmt: [
            {
                text: 'Farm Users',
                icon: <FaUsers style={{ marginBottom: 10,marginLeft:2}} />,
                link: '/users',
            }
        ],
        // '360farm_mgmt': [
        //     {
        //         text: 'Statistics',
        //         icon: <EqualizerIcon  sstyle={{ marginBottom: 10,marginLeft:2 }} />,
        //         link: '/360-stats',
        //     }
        // ],
        
        user_mgmt: [
            {
                text: '360 Users',
                icon: <FaRegUserCircle style={{ marginBottom: 10,marginLeft:5 }} />,
                link: '/user-management',
            }
        ],
        gptChat_mgmt: [
            {
                text: 'Farm GPT',
                icon: <IoChatbubbleSharp style={{ marginBottom: 10,marginLeft:2 }} />,
                link: '/user-chat',
            }
        ],
        agroChat_mgmt: [
            {
                text: 'Org Chats',
                icon: <IoChatbubbleSharp style={{ marginBottom: 10,marginLeft:2 }} />,
                link: '/agro-chats',
            }
        ],
        farm_mgmt: [
            {
                text: 'Farms',
                icon: <GiFarmTractor style={{ marginBottom: 10,marginLeft:2 }} />,
                link: '/farms',
            }
        ],
        env_mgmt: [
            {
                text: 'Hubs',
                icon: <VscServerEnvironment style={{ marginBottom: 10, marginLeft:2}} />,
                link: '/envs',
            }
        ],
        payment_mgmt: [
            {
                text: 'Payments',
                icon: <MdOutlinePayment style={{ marginBottom: 10, marginLeft:2}} />,
                link: '/payments',
            }
        ],
        feature_mgmt: [
            {
                text: 'Feature Management',
                icon: <BsFillHouseGearFill style={{ marginBottom: 10,marginLeft:2 }} />,
                link: '/feature-management',
            }
        ],
        role_mgmt: [
            {
                text: 'Role Management',
                icon: <FaUserPen style={{ marginBottom: 10, marginLeft:2 }} />,
                link: '/role-management',
            }
        ],
        admin_mgmt: [
            {
                text: 'Admin Management',
                icon: <MdSupervisedUserCircle style={{ marginBottom: 10,marginLeft:2 }} />,
                link: '/admin-management',
            }
        ],

       programs_360_mgmt: [
            {
                text: 'Soil Management',
                icon: <DescriptionIcon style={{ marginBottom: 10 ,marginLeft:2}} />,
                link: '/soil-test-report',
            }
        ],
        soil_reports_360_mgmt: [
            {
                text: 'Program Management',
                icon: <ListAltIcon style={{ marginBottom: 10,marginLeft:2}} />,
                link: '/view-program',
            }
        ],
        '360farmer_mgmt': [
            {
                text: 'Farmer',
                icon: <GiFarmer style={{ marginBottom: 10,marginLeft:5}} />,
                link: '/farmers',
            }
        ],

       

        "tags_360_mgmt": [
            {
                text: '360 Tags',
                icon: <TiTags style={{ marginBottom: 10, marginLeft: 7 }} />,
                link: '/tags',
            }
        ]
    };




    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* <AppBar position="fixed" open={open} style={{ backgroundColor: userData?.role?.name === "agronomist" ? "#EFEDED" : "#d0e7b7", boxShadow: 'none' }}> */}
            <AppBar position="fixed" open={open} style={{ backgroundColor: userData?.role?.name === "agronomist" ? "#EFEDED" : "#028476", boxShadow: 'none' }}>

                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={{
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                    }}>
                        <img style={{ width: '44.44px', height: '44.44px' }} src={LogoImg} alt="phyfarm" />
                    </div>
                    <Typography variant="h6" style={{ color: 'white', fontFamily: 'sen', marginLeft: 30, fontWeight: 'bold' }}>
                        Phyfarm
                    </Typography>
                    {
                        userData?.role?.name === 'agronomist' && (

                            <div style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >

                                <Button variant="contained"
                                    onClick={downloadImage}
                                    style={{ marginRight: '8px', backgroundColor: "#D9D9D9", color: 'black', borderRadius: 20, boxShadow: "none" }}>
                                    <BsQrCode style={{ marginRight: 10 }} />Download QR
                                </Button>
                                <Button variant="contained" onClick={openDialog} style={{ marginRight: '8px', backgroundColor: "#D9D9D9", color: 'black', borderRadius: 20, boxShadow: "none" }}><LuCopy style={{ marginRight: 10 }} /> Invite Link</Button>
                            </div>
                        )
                    }
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
       
                <DrawerHeader
                    sx={{ backgroundColor: userData?.role?.name === "agronomist" ? '#E3DDDD' : "white" }}
                >
                    
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                {/* <Link to="/dash" className='w-full flex justify-center'>
                              <EqualizerIcon className='mx-auto' />
                                    </Link> */}
                {
                    userData?.role?.name === 'agronomist' && (

                        <List style={{ backgroundColor: '#E3DDDD', height: '100vh' }}>
                            {['Dashboard', 'User Chat'].map((text, index) => (
                                <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton component={Link}
                                        to={
                                            index === 0 ? '/users-dashboard' :
                                                index === 1 ? '/users-chat' :
                                                    null
                                        }
                                        sx={{ borderRadius: '0px 50px 50px 0px' }}
                                    >
                                        <ListItemIcon>
                                            {
                                                index === 0 ? <DashboardIcon /> :
                                                    index === 1 ? <Badge badgeContent={unreadMSG} showZero color="success"><IoChatbubbleSharp /> </Badge> : null
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )
                }


                {
                    userData?.role?.name === 'agronomist' ? null :
                        (
                            userData?.role?.name === 'superadmin' ? (
                                <List style={{ marginTop: 15, display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                                    {
                                        permissions?.user_permissions?.permissions.slice().reverse().map((permission) => {
                                            let sidebarItems = featureToSidebarItems[permission.feature_name];
                                            if (sidebarItems && permission.permissions.view) {
                                                return sidebarItems.map((item, index) => {
                                                    
                                                    return (
                                                        <ListItem key={index} disablePadding>
                                                            <ListItemButton onClick={() => navigate(item.link, { replace: true })}>
                                                                <ListItemIcon style={{
                                                                    fontSize: 18,
                                                                }} >
                                                                    {item.icon}
                                                                </ListItemIcon>
                                                                <Link to={item.link} style={{ color: 'black', textDecoration: 'none', marginBottom: 10, fontFamily: 'sen', textAlign: 'center', paddingRight: 12 }}>
                                                                    {item.text}
                                                                </Link>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    );
                                                });
                                            }
                                            return null;
                                        })
                                    }
                                </List>
                            ) : (
                                <List style={{ marginTop: 15 }}>
                          <NavLink to="/dashboard" style={{ textDecoration: 'none' }}>
      <ButtonBase
        sx={{
          width: '100%',
          height:'50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: '#f2f3f5',
          },
        }}
      >
        <div className="w-full flex justify-center items-center">
          <EqualizerIcon
            className="mx-auto text-center"
            sx={{
              color: '#757575',
              transition: 'color 0.3s',
            
            }}
          />
        </div>
      </ButtonBase>
    </NavLink>    
                         

                                    {permissions?.user_permissions?.permissions.map((permission) => {
                                        const sidebarItems = featureToSidebarItems[permission.feature_name];

                                        if (sidebarItems && permission.permissions.view) {
                                            return sidebarItems.map((item, index) => {<>
                                            </>
                                            
                                                return (
                                                    <>
                                                    <ListItem key={index} disablePadding>
                                                        <ListItemButton  onClick={() => navigate(item.link, { replace: true })}>
                                                
                                                            <ListItemIcon style={{
                                                                fontSize: 18,
                                                            }} >
                                                                {item.icon}
                                                            </ListItemIcon>
                                                            <Link to={item.link} style={{ color: 'black', textDecoration: 'none', marginBottom: 10, fontFamily: 'sen', textAlign: 'center', paddingRight: 12 }}>
                                                                {item.text}
                                                            </Link>
                                                        </ListItemButton>
                                                    </ListItem>
                                                
                                                    </>
                                                );
                                            });
                                        }
                                        
                                        return null;
                                    })}
                                    <div className='flex flex-col'>
{/*                                
                                    <Link to="/dcm-dashboard">
                                    dcm
                                    </Link> */}
                                    {/* <Link to="/add-program">
                                    add
                                    </Link> */}
                             
                                    </div>
                                </List>
                            )
                        )
                }

                <div style={{ position: 'absolute', bottom: 20, width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <ListItem primary="Logout" disablePadding  >
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <RiLogoutCircleLine
                                    style={{ color: 'black', marginLeft: 7 }}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </div>
            </Drawer>


            <Dialog open={openCopyDialog} onClose={openDialog}>
                <DialogTitle style={{ fontSize: 18, fontWeight: 'bold', backgroundColor: '#EFEDED', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Invite Link
                    <IconButton onClick={() => setOpenCopyDialog(false)} ><IoCloseOutline /></IconButton>

                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '550px', height: '120px', backgroundColor: '#D9D3D3' }}>
                    <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 20, width: '100%', textAlign: 'center' }}>
                        {getInviteLink() || "Invite link not available"}
                    </div>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default NavigationBar;
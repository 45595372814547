import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSelect = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
  },
  '& .MuiInputLabel-root': {
    color: '#008476',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#008476',
  },
});

const OrderByFilter = ({ orderBy, onChange }) => {
  return (
    <StyledSelect
      select
      label="Order By"
      variant="outlined"
      value={orderBy}
      onChange={(e) => onChange(e.target.value)}
      sx={{
        width: 150,
        '& .MuiSelect-select': {
          color: 'black',
        },
      }}
    >
      <MenuItem value="ASC">Ascending</MenuItem>
      <MenuItem value="DSC">Descending</MenuItem>
    </StyledSelect>
  );
};

export default OrderByFilter;

import { useState, useEffect } from 'react';

// transform zone boundaries lat, lng to latitude, longitude
export const transformBoundaries = (boundaries) => {
    const transformedBoundaries = Array.isArray(boundaries) && boundaries.map(boundary => {
        return {
            latitude: boundary.lat,
            longitude: boundary.lng
        }
    });

    return transformedBoundaries;
}

//check if user has permission to create
export const createPermission = (name , data) => {
    return data?.user_permissions?.permissions?.some(
        permission =>
            permission.feature_name === name &&
            permission.permissions.create
    )
}

//check if user has permission to delete
export const deletePermission = (name, data) => {
    return data?.user_permissions?.permissions?.some(
        permission =>
            permission.feature_name === name &&
            permission.permissions.delete
    )
}

//check if user has permission to view
export const viewPermission = (name, data) => {
    return data?.user_permissions?.permissions?.some(
        permission =>
            permission.feature_name === name &&
            permission.permissions.view
    )
}

//check if user has permission to edit
export const editPermission = (name, data) => {
    return data?.user_permissions?.permissions?.some(
        permission =>
            permission.feature_name === name &&
            permission.permissions.edit
    )
}
//check if phone is valid
export const validPhoneNumber = (phoneNumber) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber);
}

//check if email is valid
export const  isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

// format time to 12 hour format - AM and PM ( eg:- 12:30PM)
export const formatTimeTo12HR = (dateTimeString) => {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;

    return `${hours}:${minutes} ${amPm}`;
}

//format date to MM/DD/YY
export const formatDateToMMDDYY = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
}

//format date to long format (eg:- JUN 23, 2024)
export const  formatDateLong = (inputDate) => {
  
    const date = new Date(inputDate);
    const monthNames = [
        "Jan", "Feb", "Mar",
        "Apr", "May", "Jun", "Jul",
        "Aug", "Sep", "Oct",
        "Nov", "Dec"
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
}

export const formatTimeLong = (inputTime) => {

    const [hours, minutes] = inputTime.split(':').map(num => parseInt(num));
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const formattedTime = `${formattedHours} : ${formattedMinutes} ${period}`;

    return formattedTime;
}

//toast message that comes on success and failure
export const useToast = (
    initialState = false,
    successMessage = 'Success!',
    validationErrors = {}
  ) => {
    const [open, setOpen] = useState(initialState);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('success');
  
    useEffect(() => {
      if (open) {
        const timer = setTimeout(() => {
          setOpen(false);
        }, 2000);
  
        return () => clearTimeout(timer);
      }
    }, [open]);
  
    const showSuccessToast = () => {
      setMessage(successMessage);
      setType('success');
      setOpen(true);
    };
  
    const showValidationErrorToast = (errors) => {
      let errorMessage = '';
      for (const [, message] of Object.entries(errors)) {
        errorMessage += `${message}\n`;
      }
      setMessage(errorMessage.trim());
      setType('error');
      setOpen(true);
    };
  
    return [{ open, message, type }, showSuccessToast, showValidationErrorToast];
  };
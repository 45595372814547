import React from "react";
import { Route, Routes } from "react-router-dom";
import Leads from "./components/Leads";
import AddLead from "./components/AddLead";
import Login from "./pages/Login";
import store from "./store/store";
import { Provider } from "react-redux";
import EditFarm from "./pages/EditFarm";
import LeadDetails from "./components/LeadDetails";
import { Users } from "./pages/Users";
import UserDetails from "./pages/UserDetails";
import RegisterForm from "./pages/RegisterForm";
import AddRequirement from "./components/AddRequirement";
import EditRequirement from "./components/EditRequirement";
import UserChat from "./pages/UserChat";
import AgroChat from "./pages/AgroChat";
import AgroDashboard from "./pages/AgroDashboard";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Confirmation from "./pages/Confirmation";
import UserStats from "./components/UserStats";
import AgroChatListing from "./pages/AgroChatListing";
import ListFarms from "./pages/ListFarms";
import ListAllEnvs from "./pages/ListAllEnvs";
import Payment from "./pages/Payment";
import AddZone from "./pages/AddZone";
import EditZone from "./pages/EditZone";
import Devices from "./pages/Devices";
import AdminManagament from "./pages/AdminManagement";
import UserManagement from "./pages/UserManagement";
import RoleManagement from "./pages/RoleManagement";
import FeatureManagement from "./pages/FeatureManagement";
import ProgramMembers from "./pages/ProgramMembers";
import AddProgram from "./pages/AddProgram";
import ViewProgram from "./pages/ViewProgram";
import SingleProgram from "./pages/SingleProgram";
import Dashboard360Stats from "./pages/Dashboard360Stats";
import SoilTestReports from "./pages/SoilTestReports";
import SingleSoilTestReport from "./pages/SingleSoilTestReport";
import ProgramActivity from "./pages/ProgramActivity";
import Tags from "./pages/Tags";
import Farmer from "./pages/Farmer";

function App() {
  return (
    <Provider store={store}>
      <Routes>
        {/* auth routes */}
        <Route path="/" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/resetPassword/:acessToken" element={<ResetPassword />} />

        <Route path="/leads" element={<Leads />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/add-lead" element={<AddLead />} />
        <Route path="/lead-details" element={<LeadDetails />} />

        <Route path="/add-requirement/:id" element={<AddRequirement />} />
        <Route
          path="/edit-requirement/:id/:leadId"
          element={<EditRequirement />}
        />

        {/* routes for agro chat */}
        <Route path="/users-chat" element={<AgroChat />} />
        <Route path="/users-dashboard" element={<AgroDashboard />} />
        <Route path="/user-stats" element={<UserStats />} />

        {/* <------ phyfarm360 -----------> */}

        {/* admin routes */}
        <Route path="/users" element={<Users />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/user-chat" element={<UserChat />} />
        <Route path="/agro-chats" element={<AgroChatListing />} />
        <Route path="/farms" element={<ListFarms />} />
        <Route path="/envs" element={<ListAllEnvs />} />
        <Route path="/devices/:envId" element={<Devices />} />
        <Route path="/payments" element={<Payment />} />

        <Route path="/userdetails/:userId" element={<UserDetails />} />
        <Route
          path="/register-form/:fullName/:userId"
          element={<RegisterForm />}
        />
        <Route path="/add-zone/:farmId/:userId" element={<AddZone />} />
        <Route path="/edit-farms/:farmId/:userId" element={<EditFarm />} />
        <Route
          path="/edit-zone/:farmId/:zoneId/:userId"
          element={<EditZone />}
        />

        {/* superadmin routes */}
        <Route path="/admin-management" element={<AdminManagament />} />
        <Route path="/role-management" element={<RoleManagement />} />

        <Route path="/feature-management" element={<FeatureManagement />} />

        {/* program */}
        <Route path="/program-view-members/:id" element={<ProgramMembers />} />
        <Route path="/add-program" element={<AddProgram />} />
        <Route path="/view-program" element={<ViewProgram />} />
        <Route path="/view-program/:id" element={<SingleProgram />} />
        <Route path="/view-activity/:id" element={<ProgramActivity />} />

        {/* farmer */}
        <Route path="/farmers" element={<Farmer />} />

        {/* statistics */}
        <Route path="/dashboard" element={<Dashboard360Stats />} />

        {/* soil test reports */}
        <Route path="/soil-test-report" element={<SoilTestReports />} />
        <Route
          path="/soil-test-report/:id"
          element={<SingleSoilTestReport />}
        />

        <Route path="/tags" element={<Tags />} />
      </Routes>
    </Provider>
  );
}

export default App;

import { baseURL } from "../../config";

export const fetchFarmers = async (payload) => {
  try {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
    const response = await fetch(
      `${baseURL}/api/mgmt/360-farmer/list`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken: accessToken,
        },
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching farmers:", error);
    throw error;
  }
};


export const fetchUsers = async () => {
  try {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const org_id = userData.org.id;
    const payload={org_id}
    const response = await fetch(`${baseURL}/api/mgmt/360-user/listUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    console.log(data,"hm");
    
    return data.data.users;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};
import React, { useState, useEffect } from "react";
import { 
  TextField, 
  MenuItem, 
  InputAdornment, 
  IconButton 
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const StyledSearchField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    paddingRight: theme.spacing(1),
    "& fieldset": {
      borderColor: "#008476", // Border color for the search field
    },
    '& .MuiInputLabel-root': {
      color: '#008476',
    },
    "&:hover fieldset": {
      borderColor: "#006955", // Darker color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#008476", // Focus color
    },
  },
}));

const StyledSelectMenu = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& fieldset": {
      borderColor: "transparent", // No visible border
    },
  },
  "& .MuiSelect-root": {
    color: "#008476", // Select text color
  },
}));

const FarmerSearch = ({ onSearch }) => {
  const [searchType, setSearchType] = useState("phone");
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  useEffect(() => {
    const searchFilters = debouncedSearchQuery
      ? {
          [searchType === "phone" ? "phone" : "fullname"]:
            debouncedSearchQuery,
        }
      : {};

    onSearch(searchFilters);
  }, [debouncedSearchQuery, searchType, onSearch]);

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <StyledSearchField
      // fullWidth
      sx={{
        width:"50%"
      }}
      variant="outlined"
      placeholder="Search Farmers"
      value={searchQuery}
      label="Search"

      onChange={(e) => setSearchQuery(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: "#008476" }} />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {searchQuery && (
              <IconButton
                size="small"
                onClick={handleClearSearch}
                edge="end"
                sx={{
                  color: "#008476", // Clear icon color
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
            <StyledSelectMenu
              select
              variant="outlined"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              sx={{
                minWidth: 120, // Adjust width for the dropdown
                "& .MuiSelect-select": {
                  padding: "8px",
                },
              }}
            >
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="name">Name</MenuItem>
            </StyledSelectMenu>
          </>
        ),
      }}
    />
  );
};

export default FarmerSearch;

// Custom hook for debouncing
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
